import React from 'react'
// import Section from '@components/Section'
import { Container } from 'theme-ui'
import { Box, Heading } from 'theme-ui'
import { Layout } from '@layout'
import Section from '@components/Section'

const styles = {
  heading: {
      mb: 4,
      color: `omegaDark`,
      textAlign: `center`,
  },
  item: {
    display: `inline-block`,
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`,
    mb: 4,
  }

}

const HomePageIntro = ({ headerOne, descText, children, ...props }) => (
  <Box>
    <Section sx={styles.item}>
        <Container>
            <Heading variant='h1' as="h1" sx={styles.heading}>
                {headerOne}
            </Heading>
        <Heading variant='h3' as="span" sx={styles.running}>
        {descText}
      </Heading>
        </Container>
    </Section>
  </Box>
)

export default HomePageIntro
